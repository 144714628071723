.navbar-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.navbar-image {
  width: 160px;
  height: auto;
}
.navbar-nav {
  display: flex;
}
.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-image1 {
  width: 106px;
}
.navbar-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-primary-btn {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.navbar-social-media {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.navbar-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.navbar-icon04 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.navbar-icon04:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.navbar-icon08 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.navbar-icon08:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.navbar-icon12 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.navbar-icon12:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.navbar-icon14 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}
.navbar-icon14:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

@media(max-width: 767px) {
  .navbar-nav {
    display: none;
  }
  .navbar-primary-btn1 {
   
  }
}
@media(max-width: 479px) {
  .navbar-icon {
    width: 55px;
    height: 49px;
  }
}
